/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { CurvedArrowSvg, MEDIA, SECTION_IDS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { ReadMoreWrapper } from "@Modules/common/ReadMoreWrapper";
import { FollowerCountIcons } from "@Modules/hostPage/FollowerCountIcons";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import {
    TEMPLATE_CONFIG,
    TEMPLATE_KEYS,
} from "repoV2/features/CreatorPages/CreatorPages.constants";
import commonStyles from "../../root/yoga.module.scss";
import styles from "./aboutSection.module.scss";

export const AboutSection = ({
    coverImg,
    title,
    name,
    shortDescription,
    getInTouchCtaText,
    onGetInTouchClick,
    profileImg,
    description,
    preview,
    hideSocialIcons,
    socialLinks,
    experience,
    expandHostDesc,
    hideGetInTouch,
}: ITemplate.IAboutSectionProps) => {
    const experienceYears = experience?.years?.trim() || (preview && "08");
    const experienceLabel = experience?.label?.trim() || "years of experience";

    const showExperience = experienceYears;

    return (
        <div className={styles.container}>
            <a id={SECTION_IDS.about} className={commonStyles.sectionAnchor} />
            <div className={styles.bannerContainer}>
                <ExlyImage
                    fetchWidth={
                        TEMPLATE_CONFIG[TEMPLATE_KEYS.YOGA].coverImage.width
                    }
                    width={TEMPLATE_CONFIG[TEMPLATE_KEYS.YOGA].coverImage.width}
                    alt="background"
                    src={coverImg || MEDIA.DEFAULT.BACKGROUND.YOGA}
                    className={styles.coverImage}
                />
                <div
                    className={classNames(
                        styles.bannerTextContainer,
                        commonStyles.uniformWidth
                    )}
                >
                    {title ? (
                        <h1
                            className={classNames(
                                commonStyles.sectionTitle,
                                styles.title
                            )}
                        >
                            {title}
                        </h1>
                    ) : null}
                    {shortDescription ? (
                        <div
                            className={classNames(
                                styles.shortDescription,
                                styles.desktopOnly
                            )}
                        >
                            {shortDescription}
                        </div>
                    ) : null}
                    {hideGetInTouch ? null : (
                        <div className={styles.getInTouchButtonContainer}>
                            <ButtonV2
                                applyTheme
                                onClick={() => {
                                    onGetInTouchClick();
                                }}
                                className={styles.getInTouchButton}
                            >
                                {getInTouchCtaText}
                            </ButtonV2>
                            <CurvedArrowSvg
                                className={styles.arrowIcon}
                                stroke="rgb(var(--rgbColor2-schema1-host-theme, 255, 255, 255))"
                            />
                        </div>
                    )}
                </div>
                <div className={styles.clipWhite} />
            </div>
            <div
                className={classNames(
                    commonStyles.uniformWidth,
                    styles.aboutCreatorContainer
                )}
            >
                {shortDescription ? (
                    <div
                        className={classNames(
                            styles.shortDescription,
                            styles.mobileOnly
                        )}
                    >
                        {shortDescription}
                    </div>
                ) : null}

                <div className={styles.profileImgContainer}>
                    <ExlyImage
                        fetchWidth={750}
                        alt={title || ""}
                        className={classNames(
                            commonStyles.borderRadius,
                            styles.profileImg
                        )}
                        src={profileImg || DEFAULT_PROFILE_PIC_SRC}
                    />
                    {showExperience ? (
                        <div
                            className={classNames(
                                styles.profileImgTextContainer,
                                commonStyles.borderRadius
                            )}
                        >
                            <div
                                className={classNames(
                                    commonStyles.bigText,
                                    styles.profileImgBigText
                                )}
                            >
                                {experienceYears}
                            </div>
                            <div className={styles.profileImgText}>
                                {experienceLabel}
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className={styles.aboutCreatorTextContainer}>
                    {name ? (
                        <div className={commonStyles.bigText}>{name}</div>
                    ) : null}
                    <div className={commonStyles.smallSectionContainer}>
                        {description ? (
                            <>
                                {expandHostDesc ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                ) : (
                                    <ReadMoreWrapper
                                        id="yoga_aboutSection_description"
                                        collapsedHeight={170}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: description,
                                            }}
                                        />
                                    </ReadMoreWrapper>
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className={commonStyles.smallSectionContainer}>
                        <FollowerCountIcons
                            socialLinks={socialLinks}
                            hideSocialIcons={hideSocialIcons}
                        />
                    </div>
                    {hideGetInTouch ? null : (
                        <ButtonV2
                            applyTheme
                            onClick={() => {
                                onGetInTouchClick();
                            }}
                            className={styles.getInTouchButton}
                        >
                            {getInTouchCtaText}
                        </ButtonV2>
                    )}
                </div>
            </div>
        </div>
    );
};
