/* eslint-disable jsx-a11y/anchor-has-content */
import { ROUTES, SECTION_IDS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { BlogPostCard } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import styles from "./blogPostsSection.module.scss";
import commonStyles from "../../root/yoga.module.scss";

export const BlogPostsSection = ({
    hideComponent,
    title,
    blogPosts,
    preview,
    handlePreviewPopup,
}: ITemplate.IBlogPostsSectionProps) => {
    if (hideComponent) return null;

    return (
        <div className={styles.root}>
            <a id={SECTION_IDS.blog} className={commonStyles.sectionAnchor} />
            <div
                className={classNames(
                    commonStyles.uniformWidth,
                    styles.container
                )}
            >
                <div
                    className={classNames(
                        commonStyles.sectionTitle,
                        styles.title
                    )}
                >
                    {title}
                </div>
                <div className={styles.blogPostsContainer}>
                    {blogPosts.map(blogPost => (
                        <BlogPostCard
                            key={blogPost.uuid}
                            data={blogPost}
                            preview={preview}
                            handlePreviewPopup={handlePreviewPopup}
                            className={styles.blogPostContainer}
                        />
                    ))}
                </div>
                <a
                    href={ROUTES.BLOG.urlPath}
                    className={styles.blogLink}
                    onClick={e => {
                        if (preview) {
                            handlePreviewPopup();
                            e.preventDefault();
                        }
                    }}
                >
                    View More Blog Posts
                </a>
            </div>
        </div>
    );
};
