import { ITemplate } from "@Templates/ITemplate";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import commonStyles from "../../root/yoga.module.scss";
import styles from "./StatsSection.module.scss";

export const StatsSection = ({
    hideComponent,
    data,
}: ITemplate.IStatsSectionProps) => {
    if (hideComponent) return null;
    return (
        <div className={classNames(styles.root)}>
            <div className={commonStyles.uniformWidth}>
                <div className={styles.container}>
                    {data.map(stat => (
                        <div className={styles.statContainer} key={stat.id}>
                            <div className={commonStyles.bigText}>
                                {stat.value}
                            </div>
                            <div>{stat.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
