import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { TEMPLATE_KEYS } from "@Utils";
import { TeamMemberSocialIcons } from "@Modules/hostPage";
import styles from "./teamMemberCard.module.scss";
import commonStyles from "../../../root/yoga.module.scss";

export const TeamMemberCard = ({
    data,
    cardClick,
}: ITemplate.ITeamMemberCardProps) => {
    return (
        <div className={styles.root} onClick={() => cardClick(data)}>
            <div
                className={classNames(
                    styles.container,
                    commonStyles.borderRadius
                )}
            >
                <div
                    className={classNames(
                        styles.image,
                        commonStyles.borderRadius
                    )}
                    style={{ backgroundImage: `url(${data?.displayImage})` }}
                >
                    <TeamMemberSocialIcons
                        template={TEMPLATE_KEYS.YOGA}
                        socialInfo={data.socialInfo}
                    />
                </div>
                <div className={styles.detailsContainer}>
                    <div className={styles.name}>
                        {data.memberName.slice(0, 21)}
                    </div>
                    {/* TODO: delete if not used till 18th july 2022 */}
                    {/* <div className={styles.heading}>
                        {data.staffHeading.slice(0, 60)}
                    </div>
                    <hr className={styles.horizontalBar} />
                    <div className={styles.description}>
                        {data.staffDescription.slice(0, 300)}
                    </div> */}

                    {data.staffHeading ? (
                        <div className={styles.heading}>
                            {data.staffHeading.slice(0, 60)}
                        </div>
                    ) : null}
                    {data.staffHeading && data.staffDescription ? (
                        <hr className={styles.horizontalBar} />
                    ) : null}
                    {data.staffDescription ? (
                        <div className={styles.description}>
                            {data.staffDescription.slice(0, 300)}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
