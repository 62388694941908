/* eslint-disable jsx-a11y/anchor-has-content */
import { ICategorisedEventList } from "@Modules/hostPage/CategorisedEventList/ICategorisedEventList";
import { SECTION_IDS } from "@Utils";
import React from "react";
import styles from "./eventSectionsContainer.module.scss";
import commonStyles from "../../../../root/yoga.module.scss";

export const EventSectionsContainer: ICategorisedEventList["EventSectionsContainer"] =
    ({ children }) => {
        return (
            <div className={styles.root}>
                <div className={commonStyles.uniformWidth}>
                    <a
                        id={SECTION_IDS.eventSections}
                        className={commonStyles.sectionAnchor}
                    />
                    {children}
                </div>
            </div>
        );
    };
