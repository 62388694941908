import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { Navbar } from "@Modules/common";
import { FooterSection } from "repoV2/features/CreatorPages/modules/FooterSection";
import { BrandsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/BrandsList";
import { AwardsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/AwardsList";
import { MediaList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/MediaList";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import styles from "./yoga.module.scss";
import { AboutSection } from "../components/AboutSection";
import { TopFeaturesSection } from "../components/TopFeaturesSection";
import { StatsSection } from "../components/StatsSection";
import { TestimonialsSection } from "../components/TestimonialsSection";
import { TeamSection } from "../components/TeamSection";
import { BlogPostsSection } from "../components/BlogPostsSection";
import { EventSections } from "../components/EventSections";
import Portfolio from "../components/Portfolio";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";

const Yoga = ({
    navbarProps,
    aboutSectionProps,
    eventSectionsProps,
    testimonialsSectionProps,
    teamSectionProps,
    blogPostsSectionProps,
    footerSectionProps,
    gallerySectionProps,
    topFeaturesProps,
    statsSectionProps,
}: ITemplate.IProps): JSX.Element => {
    return (
        <div className={styles.root}>
            <Navbar {...navbarProps} />
            <div className={styles.container}>
                <AboutSection {...aboutSectionProps} />
                <CustomCodeSection
                    position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
                />
                <AwardsList titleClass={`${styles.sectionTitle} mb-4`} />
                <EventSections {...eventSectionsProps} />
                <TopFeaturesSection {...topFeaturesProps} />
                <BrandsList titleClass={`${styles.sectionTitle}`} />
                <StatsSection {...statsSectionProps} />
                <TestimonialsSection {...testimonialsSectionProps} />
                <TeamSection {...teamSectionProps} />
                <MediaList
                    titleClass={`${styles.sectionTitle}`}
                    conatinerClass={styles.mediaListContainer}
                />
                <BlogPostsSection {...blogPostsSectionProps} />
                <Portfolio {...gallerySectionProps} />
                <CustomCodeSection
                    position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
                />
                <FooterSection {...footerSectionProps} />
            </div>
        </div>
    );
};

export default Yoga;
