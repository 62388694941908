import { ITemplate } from "@Templates/ITemplate";
import { classNames } from "repoV2/utils/common/render/classNames";
import { MEDIA } from "@Utils";
import React from "react";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import commonStyles from "../../root/yoga.module.scss";
import styles from "./TopFeaturesSection.module.scss";

export const TopFeaturesSection = ({
    hideComponent,
    title,
    image,
    data,
}: ITemplate.ITopFeaturesSectionProps) => {
    if (hideComponent) return null;
    return (
        <div
            className={classNames(
                commonStyles.uniformWidth,
                commonStyles.sectionContainer,
                styles.container
            )}
        >
            <div
                className={classNames(
                    styles.sectionTitle,
                    commonStyles.sectionTitle
                )}
            >
                {title}
            </div>
            <div className={styles.sectionBody}>
                <ExlyImage
                    /**
                     *  DEV - Adding this as fetch Width as 868px because in case
                     *  of mobile view maxium image width went upto 900px
                     */
                    fetchWidth={900}
                    alt={title}
                    src={image || MEDIA.DEFAULT.YOGA_FEATURES}
                    className={classNames(
                        styles.mainImage,
                        commonStyles.borderRadius
                    )}
                    loading="lazy"
                />
                <div className={styles.detailsContainer}>
                    {data.map(item => (
                        <div className={styles.detailContainer} key={item.id}>
                            <ExlyImage
                                fetchWidth={200}
                                alt={title}
                                src={
                                    item.media ||
                                    MEDIA.DEFAULT.PLACEHOLDER_IMAGE
                                }
                                width={100}
                                height={100}
                                loading="lazy"
                                className={commonStyles.borderRadius}
                            />
                            <div className={styles.textContainer}>
                                <div
                                    className={classNames(
                                        styles.textHeading,
                                        commonStyles.sectionTitle
                                    )}
                                >
                                    {item.title}
                                </div>
                                <div
                                    className={classNames(
                                        styles.textBody,
                                        commonStyles.sectionDescription
                                    )}
                                >
                                    {item.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
