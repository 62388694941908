import { ButtonV2 } from "@Modules/common/ButtonV2";
import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import { colorByListingType, MEDIA, trimText } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./eventListing.module.scss";
import commonStyles from "../../../../root/yoga.module.scss";

export const EventListing = ({
    data,
    showSkuLabelAsTag = false,
    onEventListingClick,
}: {
    data: ITemplate.IEventListingData;
    showSkuLabelAsTag?: boolean;
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
}) => {
    const metadata = data?.metadata;
    const cardLabelContent = metadata?.card_label?.content;
    const cardLabelColor = metadata?.card_label?.color || "white";
    const cardLabelBgColor = metadata?.card_label?.background || "black";
    return (
        <div
            className={classNames(styles.container, commonStyles.borderRadius)}
            onClick={() => onEventListingClick(data)}
        >
            <div className={styles.imageContainer}>
                <ExlyImage
                    alt={data.title}
                    className={classNames(
                        styles.image,
                        commonStyles.borderRadius
                    )}
                    src={data.cover_image || MEDIA.DEFAULT.CARD_IMAGE}
                    fetchWidth={500}
                    width={340}
                    height={167.25}
                />
                <div className={styles.labelsContainer}>
                    {showSkuLabelAsTag ? (
                        <div
                            className={classNames(
                                styles.label,
                                commonStyles.borderRadius
                            )}
                            style={{
                                backgroundColor: colorByListingType(
                                    data.type,
                                    "isPlan" in data && data.isPlan
                                ),
                            }}
                        >
                            {("sku_title" in data && data.sku_title) ||
                                ("listingTypeTitle" in data &&
                                    data.listingTypeTitle) ||
                                ""}
                        </div>
                    ) : null}
                    {cardLabelContent ? (
                        <div
                            className={classNames(
                                styles.label,
                                commonStyles.borderRadius
                            )}
                            style={{
                                color: cardLabelColor,
                                backgroundColor: cardLabelBgColor,
                            }}
                        >
                            {cardLabelContent}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={styles.detailsContainer}>
                <div className={styles.titleContainer}>
                    <b className={styles.title}>{trimText(data.title, 76)}</b>
                    {!metadata?.hide_schedule ? (
                        <div
                            className={styles.shortDescription}
                            // TODO: remove trimText from dangerouslySetInnerHTML as its not a good approach to trim HTML string directly use ReadMoreWrapper
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: trimText(data.short_description, 100),
                            }}
                        />
                    ) : null}
                </div>
                <div>
                    <hr />
                    <div className={styles.price}>
                        {data.hidePrice ? (
                            <>&nbsp;</>
                        ) : (
                            `${data?.currency} ${Math.round(
                                data.updated_price
                            )}`
                        )}
                    </div>
                    <ButtonV2 applyTheme className={styles.ctaButton}>
                        {data.ctaText}
                    </ButtonV2>
                </div>
            </div>
        </div>
    );
};

EventListing.defaultProps = {
    showSkuLabelAsTag: false,
};
