/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { BsCaretDownFill as DownIcon } from "@react-icons/all-files/bs/BsCaretDownFill";
import { BsCaretUpFill as UpIcon } from "@react-icons/all-files/bs/BsCaretUpFill";

import { CategorisedEventList } from "@Modules/hostPage";
import { ICategorisedEventList } from "@Modules/hostPage/CategorisedEventList/ICategorisedEventList";
import { ITemplate } from "@Templates/ITemplate";
import { classNames } from "repoV2/utils/common/render/classNames";
import {
    useEventCategorisationKeywords,
    useListTruncation,
    SECTION_IDS,
    scrollToElement,
    EVENT_CATEGORISATION_TYPE,
} from "@Utils";
import { Keywords, LoadingIndicator } from "@Modules/common";
import { ButtonV2 } from "@Modules/common/ButtonV2";

import { EventSectionsContainer } from "./components/EventSectionsContainer";
import { EventSectionTitle } from "./components/EventSectionTitle";
import { EventListing } from "./components/EventListing";
import commonStyles from "../../root/yoga.module.scss";
import styles from "./eventsSections.module.scss";

export const EventSections = ({
    sectionTitle,
    eventListings,
    onEventListingClick,
    allKeywords,
    allEventCategories,
    categoriseType,
    customTruncateExpandLabels,
    showSkuLabelAsTag,
    template,
}: ITemplate.IEventSectionsProps) => {
    const truncateListings =
        categoriseType === EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION &&
        eventListings.length > 3;

    const [
        truncatedEventListings,
        { allShown: allListingsShown, handleShowMore, handleShowLess },
    ] = useListTruncation<typeof eventListings[0]>(eventListings, {
        baseLength: 3,
    });

    const handleCollapse = () => {
        handleShowLess();
        scrollToElement(`#${SECTION_IDS.eventSections}`);
    };
    const EventSection: ICategorisedEventList["EventSection"] = ({
        eventListProp,
        index,
        category,
        showKeywords,
    }) => {
        const {
            filteredEventList,
            loading,
            keywords,
            onKeywordClick,
            selectedKeyword,
        } = useEventCategorisationKeywords(eventListProp, allKeywords);

        return (
            <div key={index} className={styles.eventSectionContainer}>
                <EventSectionTitle eventSectionTitle={category} index={index} />
                {showKeywords ? (
                    <Keywords
                        keywords={keywords}
                        selectedKeyword={selectedKeyword}
                        keywordClick={onKeywordClick}
                        template={template}
                        styles={{
                            keyword: classNames(
                                styles.keyword,
                                commonStyles.borderRadius
                            ),
                            selectedKeyword: styles.selectedKeyword,
                        }}
                    />
                ) : null}
                {loading ? (
                    <LoadingIndicator applyTheme />
                ) : (
                    <div className={styles.events}>
                        {filteredEventList.map(filteredEvent => (
                            <EventListing
                                key={filteredEvent.key}
                                data={filteredEvent}
                                showSkuLabelAsTag={showSkuLabelAsTag}
                                onEventListingClick={onEventListingClick}
                            />
                        ))}
                    </div>
                )}
                {truncateListings ? (
                    <ButtonV2
                        className={styles.loadMore}
                        design="plain"
                        onClick={
                            allListingsShown ? handleCollapse : handleShowMore
                        }
                    >
                        {allListingsShown ? (
                            <>
                                Collapse
                                <UpIcon className={styles.loadIcon} />
                            </>
                        ) : (
                            <>
                                {customTruncateExpandLabels ||
                                    "View More Classes"}
                                <DownIcon className={styles.loadIcon} />
                            </>
                        )}
                    </ButtonV2>
                ) : null}
            </div>
        );
    };

    return (
        <CategorisedEventList
            eventSectionsTitle={sectionTitle}
            EventSectionsContainer={EventSectionsContainer}
            EventSection={EventSection}
            allCategories={allEventCategories}
            eventListings={
                truncateListings ? truncatedEventListings : eventListings
            }
            categoriseType={categoriseType}
        />
    );
};
